import './src/styles/theme.scss';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import AOS from 'aos';

const options = {
  duration: 700,
  easing: 'ease-out-quad',
  once: true,
  startEvent: 'load',
};
AOS.init(options);

export const onRouteUpdate = () => {
  if (window.dataLayer) {
    window.dataLayer.push({ event: 'gtm.load' });
  }
};
