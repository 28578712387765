module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-M8KKB34","includeInDevelopment":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fourwalls Blog","short_name":"Fourwalls","start_url":"/","background_color":"#ffffff","theme_color":"#ed2124","display":"minimal-ui","icon":"content/assets/fourwalls-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"d0e73e4d36fb16ffccb251ef0c428686"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
